import React from "react";
import Navbar from "./navbar";
import NeuroTest from "./content";

const Main = () => {
  return (
    <div>
      <Navbar />
      <NeuroTest />
    </div>
  );
};

export default Main;