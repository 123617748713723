import React from 'react';
import './App.css';
import Main from './components/main';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NeuroTest from './components/content';

function App() {
  const navbarLinks = [
    { to: '/neurotest/getApproved', text: 'Get Approved' },
    { to: '/neurotest/getLinks', text: 'Get Links' },
    { to: '/neurotest/demographics', text: 'Demographics' },
    { to: '/neurotest/hoplink', text: 'HopLink' },
    { to: '/neurotest/affiliatesnote', text: 'Affiliates Note' },
    { to: '/neurotest/swipes', text: 'Swipes' },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/neurotest" element={<Main />} />
        <Route path="/neurotest/getApproved" element={<NeuroTest scrollTo="#getApproved" />} />
        <Route path="/neurotest/getLinks" element={<NeuroTest scrollTo="#getLinks" />} />
        <Route path="/neurotest/demographics" element={<NeuroTest scrollTo="#demographics" />} />
        <Route path="/neurotest/hoplink" element={<NeuroTest scrollTo="#hoplink" />} />
        <Route path="/neurotest/affiliatesnote" element={<NeuroTest scrollTo="#affiliatesnote" />} />
        <Route path="/neurotest/swipes" element={<NeuroTest scrollTo="#swipes" />} />
      </Routes>
      <Navbar links={navbarLinks} />
    </Router>
  );
}

export default App;