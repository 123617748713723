import React, { useEffect, useState } from "react";
import productImage from "../Assets/product.jpeg";
import icon from "../Assets/arrow.png";
import cross from "../Assets/cross.png";
import Footer from "./footer";
import "../Assets/style/main.css";
import banner from "../Assets/banner.jpg";
import neurtest from "../Assets/neurtest.png";
import inforIcon from "../Assets/infoIcon.jpg";
import stpLogo from "../Assets/stp-logo.png";
import stpleft from "../Assets/s1left.png";
import stpright from "../Assets/s1right.png";
import ticon from "../Assets/icon.png";
import bottles from "../Assets/bottles.png";
import checkMark from "../Assets/s1-stp-tk.png";
import diversity from "../Assets/diversity.jpeg";

const NeuroTest = ({ scrollTo }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [hopLink, setHoplink] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDownload = async () => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_PUBLIC_URL}/NT-Swipes-And-Swipe-Images.zip`;
    link.setAttribute("download", "NT-Swipes-And-Swipe-Images.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (scrollTo != null) {
      const element = document.getElementById(scrollTo);
      if (element) {
        const scrollOptions = {
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        };
        const offset =
          element.getBoundingClientRect().top - window.innerHeight / 7;

        element.scrollIntoView(scrollOptions);
        window.scrollBy(0, offset);
      } else {
        console.error(`Element with ID ${scrollTo} not found.`);
      }
    }
  }, [scrollTo]);

  return (
    <div style={{ width: "100%", marginTop: "10rem" }}>
      <section
        className="container lm-v"
        id="#getApproved"
        style={{ width: "100%" }}
      >
        <h1
          className="mb-4 fw-bold m-auto d-flex justify-content-center text-center"
          style={{ fontSize: "42px" }}
        >
          Welcome to the NeuroTest Affiliate Resources Page!
        </h1>
        <div className="container mb-5">
          <p className="text-break my-3 mb-5 text-center">
            Get ready to earn massive commissions with this amazing male health
            and sexual performance offer…
          </p>
          <div className="row d-flex justify-content-center align-items-center mt-3">
            <div className="col-lg-6 col-xs-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                <img
                  src={bottles}
                  width={"100%"}
                  style={{ objectFit: "fitWidth" }}
                  alt="product image"
                />
              </div>
            </div>

            <div className="col-lg-6 col-xs-12">
              <h4 className="text-break my-3 text-center fw-bolder mx-5">
                Get everything you need to start promoting today!
              </h4>
              <div className=" mt-4 mr-4 ">
                <button
                  className="btn btn-primary btn-lg border border-none m-auto d-flex appbutton fw-bold"
                  onClick={() => {
                    const elementId = "#getLinks";
                    const element = document.getElementById(elementId);
                    if (element) {
                      const scrollOptions = {
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      };
                      const offset =
                        element.getBoundingClientRect().top -
                        window.innerHeight / 7;

                      element.scrollIntoView(scrollOptions);
                      window.scrollBy(0, offset);
                    }
                  }}
                >
                  GET APPROVED
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container mb-5">
          <h1
            className="m-auto fw-bold d-flex justify-content-center text-center"
            style={{ fontSize: "34px" }}
          >
            Industry Leading Commissions
          </h1>
          <p className="text-break my-3 text-center">
            Approved affiliates promoting NeuroTest get 65% commission
            commission by default and up to 75% if you're running volume! Just
            get approved below to get started.
          </p>
        </div>
      </section>

      <section style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container pt-5">
          <h1
            className="m-auto fw-bold d-flex justify-content-center text-center"
            id="#getLinks"
            style={{ fontSize: "34px" }}
          >
            Get Your Links & Get Started
          </h1>
          <div className="container-fluid py-5 ">
            <div className="row">
              <div className="col-lg-12">
                <section>
                  <p className="text-break my-3">
                    <span className="h6 fw-bold" style={{ fontSize: "20px" }}>
                      NeuroTest
                    </span>{" "}
                    is available to promote quickly and easily on the ClickBank
                    affiliate network. ClickBank tracks all your clicks and
                    conversions and send out your payments automatically every
                    week! Creating an account is FREE. If you don't have a
                    Clickbank account yet,{" "}
                    <a
                      href="https://accounts.clickbank.com/master/create-account"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      visit this link
                    </a>{" "}
                    to create one.
                  </p>

                  <p className="h6">Follow the steps below to get started:</p>

                  <h5 className="fw-bold mt-3">
                    Step 1 - Get Approved, and Create A FREE ClickBank Account
                  </h5>
                  <p>
                    Fill in the form below to apply as an affiliate for
                    NeuroTest. As soon as you're approved, you're good to go.
                  </p>

                  <h5 className="fw-bold mt-3">Step 2 - Get Top Commissions</h5>
                  <p>
                    Once approved, you immediately qualify for a huge 65%
                    commission. Once you pick up traction with more sales just
                    contact us{" "}
                    <a href="mailto:affiliates@epiphanymarketing.co">here</a> to
                    get approved for up to 75% (conditions apply).
                  </p>

                  <h5 className="fw-bold mt-3">
                    Step 3 - Get Your Affiliate Links!
                  </h5>
                  <p>
                    Enter your Clickbank ID and tracking ID (optional) to
                    generate your own personalized affiliate link. Then just
                    start to send traffic to earn big payouts!
                  </p>
                  <p>
                    The Clickbank ID is{" "}
                    <span className="h6 fw-bold">NEUROTEST</span>
                  </p>

                  <p>
                    <span className="h6 fw-bold">Note:</span> Affiliates must
                    comply with the terms below . No bidding on brand terms
                    (product name) is allowed, and no deceptive, misleading,
                    untruthful, unsubstantiated, or otherwise fails to comply
                    with applicable federal and state consumer protection laws,
                    regulations, and guidelines.
                  </p>
                </section>
              </div>

              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <iframe
                  title="NeuroTest Affiliate Form"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSdyxlNAST0KzorpKa-9u92HXA_6nhLff_PVviGKG64OURYYFA/viewform?embedded=true"
                  width="640"
                  height="1758"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container"
          style={{
            minHeight: "400px",
            marginTop: "100px",
            width: "100vw",
          }}
        >
          <section>
            <h1
              className="mb-4 fw-bold m-auto d-flex justify-content-center text-center"
              id="#demographics"
              style={{ fontSize: "34px" }}
            >
              What is NeuroTest?
            </h1>
            <p className="text-center mt-2">
              NeuroTest has a completely unique hook for the testosterone, ED,
              and sexual performance space and converts on both warm and cold
              traffic.
            </p>
            <p className="text-center mt-2">
              Here's why it's gonna CRUSH and make you bank this year...
            </p>
          </section>
          <section id="" className="lm-v">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <div className="card border-0">
                    <div className="card-body text-start">
                      <div className="d-flex justify-content-start align-items-center">
                        {" "}
                        <div className="card-image">
                          <img
                            src={inforIcon}
                            className="rounded-circle"
                            alt="Product"
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          {" "}
                          <h5 className="card-title">
                            Professionally Formulated
                          </h5>
                          <p className="card-text">
                            NeuroTest has been professionally formulated and is
                            made with top-quality ingredients with effective
                            doses. This is not a low-quality “white label”
                            offer. We've taken the time to make a powerful
                            formula that customers will LOVE.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border-0">
                    <div className="card-body text-start">
                      <div className="d-flex justify-content-start align-items-center">
                        {" "}
                        <div className="card-image">
                          <img
                            src={inforIcon}
                            className="rounded-circle"
                            alt="Product"
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          {" "}
                          <h5 className="card-title">It's Unique</h5>
                          <p className="card-text">
                            NeuroTest has an incredible and NEW unique mechanism
                            - the brain! Instead of all the same old mechanisms
                            like environmental toxins that have been used for
                            years and fall flat on more aware markets.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-3 me-5">
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <div className="card border-0">
                    <div className="card-body text-start">
                      <div className="d-flex justify-content-start align-items-center">
                        {" "}
                        <div className="card-image">
                          <img
                            src={inforIcon}
                            className="rounded-circle"
                            alt="Product"
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          {" "}
                          <h5 className="card-title">Tried & Tested</h5>
                          <p className="card-text">
                            We're constantly split-testing our funnel to ensure
                            the maximum conversion and take rate so we can pay
                            out juicy commissions to our affiliates.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border-0">
                    <div className="card-body text-start">
                      <div className="d-flex justify-content-start align-items-center">
                        {" "}
                        <div className="card-image">
                          <img
                            src={inforIcon}
                            className="rounded-circle"
                            alt="Product"
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          {" "}
                          <h5 className="card-title">Best Ideas</h5>
                          <p className="card-text">
                            We've got all the best tools including copy ideas,
                            email swipes and angles for you to make bank from
                            all types of traffic: FB, display, search, native,
                            email
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-center mt-5">
                See below for the best tips, tricks and copy ideas for becoming
                successful FAST by making bank with NeuroTest.
              </p>
            </div>
            <div className=" mt-4 mr-4">
              <button
                className="btn btn-primary btn-lg  m-auto d-flex appbutton border border-none fw-bold"
                onClick={() => {
                  const elementId = "#getLinks";
                  const element = document.getElementById(elementId);
                  if (element) {
                    const scrollOptions = {
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    };
                    const offset =
                      element.getBoundingClientRect().top -
                      window.innerHeight / 7;

                    element.scrollIntoView(scrollOptions);
                    window.scrollBy(0, offset);
                  }
                }}
              >
                GET APPROVED TO RUN NOW
              </button>
            </div>
          </section>
        </div>
      </section>

      <section style={{ backgroundColor: "#f3f3f3", width: "100%" }}>
        <div className="container pt-5 pb-3">
          <h1
            className="mb-4 fw-bold m-auto d-flex justify-content-center text-center"
            id="#hoplink"
            style={{ fontSize: "32px" }}
          >
            HopLink Generator
          </h1>
          <div
            className="p-3"
            style={{
              backgroundColor: "#feffdd",
              border: "1px solid #ddde80",
              borderRadius: "12px",
            }}
          >
            <h3 className="h3 text-center text-danger mb-3">
              DEEPFAKE WARNING!
            </h3>
            <p className="text-center">
              Using AI deepfake technology to impersonate real people (such as
              celebrities, movie actors, politicians, presenters, talk show
              hosts), is <span className="text-danger">strictly forbidden</span>{" "}
              and will result in{" "}
              <span className="text-danger">
                legal action against the affiliate
              </span>{" "}
              as well as a <span className="text-danger">lifetime ban</span> and
              communications circulated to all network owners of your
              activities. We are committed to preventing all instances of this
              deepfake impersonations of real people and are monitoring
              affiliate activity to identify and remove affiliates engaging in
              it. Please promote responsibly.
            </p>
          </div>
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "12px",
              marginTop: "",
              width: "100%",
              boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
              backgroundColor: "white",
            }}
            className="m-auto my-5 container"
          >
            <div className="container my-5">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.target);
                  const formProps = Object.fromEntries(formData);
                  if (formProps["landingPageSelect"] === "Default VSL") {
                    setHoplink(
                      `https://hop.clickbank.net/?affiliate=${formProps["myId"]}&vendor=neurotest&cbpage=vsl&tid=${formProps["trackingId"]}`
                    );
                  } else if (
                    formProps["landingPageSelect"] === "Retargeting/Ecomm page"
                  ) {
                    setHoplink(
                      `https://hop.clickbank.net/?affiliate=${formProps["myId"]}&vendor=neurotest&tid=${formProps["trackingId"]}`
                    );
                  } else {
                    setHoplink("");
                  }
                }}
                style={{}}
              >
                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="myId" className="form-label">
                      My ID
                    </label>
                    <input
                      id="myId"
                      name="myId"
                      type="text"
                      className="form-control"
                      placeholder="ID"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="trackingId" className="form-label">
                      Tracking ID
                    </label>
                    <input
                      id="trackingId"
                      name="trackingId"
                      type="text"
                      className="form-control"
                      placeholder="Tracking ID"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="landingPageSelect" className="form-label">
                    Landing page
                  </label>
                  <select
                    className="form-select"
                    name="landingPageSelect"
                    id="landingPageSelect"
                    defaultValue={"Default VSL"}
                  >
                    <option value="Default VSL">Default VSL</option>
                    {/* <option value="VSL no exit pop">VSL no exit pop</option>
                    <option value="VSL no autoplay">VSL no autoplay</option>
                    <option value="VSL no exit pop & no autoplay">
                      VSL no exit pop & no autoplay
                    </option> */}
                    <option value="Retargeting/Ecomm page">
                      Retargeting/Ecomm page
                    </option>
                    {/* <option value="VSL page no headline">
                      VSL page no headline
                    </option> */}
                  </select>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center form-check"
                  style={{ gap: 10 }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="termsCheck"
                    required
                  />
                  <label className="form-check-label p-1" htmlFor="termsCheck">
                    I agree to the terms of service
                  </label>
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary d-flex m-auto appbutton border border-none fw-bold"
                >
                  SUBMIT
                </button>
              </form>
              {hopLink !== "" && (
                <div>
                  <h3 className="h3 text-center mt-5">HopLink:</h3>
                  <p
                    className="text-center mt-1 underline"
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: 5,
                      wordBreak: "break-word",
                    }}
                  >
                    {hopLink}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <div
        className="container"
        style={{
          minHeight: "400px",
          marginTop: "100px",
          width: "100vw",
        }}
      >
        <section>
          <h1
            className="text-center fw-bold"
            id="#demographics"
            style={{ fontSize: "32px" }}
          >
            Who Buys NeuroTest?
          </h1>
          <p className="text-center mt-5">
            The offer converts strongest to email lists of men 35-75 years old.
            But, it can also convert quite well to younger men who are
            interested in dating, building muscle, and financial success.
          </p>
          <p className="text-center mt-2">
            Bottom line, NeuroTest can vacuum up conversions from a wide variety
            of demographics:
          </p>

          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col-lg-6 col-xs-12">
              <div className="mb-5">
                <p className="h5 text-center mt-4">Any health lists with men</p>
                <p className="h5 text-center mt-4">Diabetes</p>
                <p className="h5 text-center mt-4">Weight loss</p>
                <p className="h5 text-center mt-4">Testosterone</p>
                <p className="h5 text-center mt-4">Conservative</p>
                <p className="h5 text-center mt-4">Dating</p>
                <p className="h5 text-center mt-4">Muscle-building</p>
                <p className="h5 text-center mt-4">Fitness</p>
                <p className="h5 text-center mt-4">Success / Finance</p>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 p-5">
              <img
                src={diversity}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  borderRadius: "12px",
                }}
              />
            </div>
          </div>
        </section>

        <section className="lm-v container">
          <div className="container">
            <h1
              className="m-auto fw-bold d-flex justify-content-center text-center"
              style={{ fontSize: "32px" }}
            >
              Why Should You Promote NeuroTest?
            </h1>
            <p
              className="text-break my-3 text-center"
              style={{ fontSize: "22px" }}
            >
              Our VSL has a gripping story, a highly emotional appeal, and
              compelling production values. We're constantly testing and
              optimizing to make sure every visitor earns you the maximum
              revenue and we'll never stop until we're on page 1.
            </p>
          </div>
        </section>

        <section className="mb-6">
          <h1
            className="m-auto d-flex fw-bold justify-content-center text-center"
            id="#affiliatesnote"
            style={{ fontSize: "32px" }}
          >
            Affiliate Terms and Rules
          </h1>
          <p className="text-center mt-4 mb-5">
            If you need any help or have any questions, please email us:{" "}
            <a href="mailto:affiliates@epiphanymarketing.co">
              affiliates@epiphanymarketing.co
            </a>
          </p>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              Do not spam in anyway.
            </p>
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              Do not advertise your own bonuses or anything additional of your
              own.
            </p>
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              Do not claim to represent NeuroTest.
            </p>
            <img
              src={cross}
              alt="arrow icon"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              Do not advertise incorrect pricing.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              Do not directly link to any order form.
            </p>
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
            />
            <p className="text-break my-1 text-center">
              No brand bidding (no PPC search campaigns using our product names
              "NeuroTest”).
            </p>
            <img
              src={cross}
              alt="arrow icon"
              className="my-1"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-break my-3 text-center text-danger">
              Anyone found doing any of the above will be blacklisted
              immediately!
            </p>
          </div>

          <div className="mt-5">
            <p className="text-center">
              <span className="h6 fw-bold">IMPORTANT:</span> All Affiliate
              methods of advertising must not include content that is deceptive,
              misleading, untruthful, unsubstantiated, or otherwise fails to
              comply with applicable federal and state consumer protection laws,
              regulations, and guidelines.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates are not
              permitted to use any terminology in ads or landing pages that are
              considered non-compliant by the FTC or FDA. Affiliates must be in
              compliance with all applicable laws, regulations, and guidelines,
              including without limitation the Federal Trade Commission Act
              (“FTC Act”),
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates are NOT
              permitted to use ANY before/after photos that belong to NeuroTest
              in online promotions without our permission.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates can NOT
              run any PPC brand campaigns. This includes but is not limited to
              Google, Facebook, Bing, Instagram, Pinterest nor bid on any
              NeuroTest branded keywords.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span>Affiliates must not
              use terms such as “scam” and “fraud” in their review articles,
              social media posts or pages about NeuroTest.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates must
              adhere to the Controlling the “CAN-SPAM Act”, Federal Trade
              Commission (“FTC”) regulations and guidelines implementing the FTC
              Act and the CAN-SPAM Act. Using spam to promote Red Boost is
              strictly forbidden.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates are
              forbidden from creating webpages, social media pages or accounts
              that falsely represent themselves as the creators or owners of
              NeuroTest products.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates may not
              link directly to the NeuroTest order form. Direct linking to cart
              or order forms from any ads or landing pages is not allowed and
              will result in permanent banning.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> Affiliates are not
              authorized to collect payments or sell any NeuroTest products from
              other websites as a "reseller". Affiliates are not authorized to
              sell products claiming to be NeuroTest, or promoting NeuroTest on
              retail sites such as eBay, Amazon, or any sites that fall into the
              category of retail sites.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span> NeuroTest will be
              solely responsible for all customer service.
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">&#8226; </span>Affiliates can NOT use
              spy tools for the intention of copying the content of other
              successful advertising campaigns. Copying or duplicating
              successful campaigns made by another affiliate will result in an
              immediate and permanent ban of your account.
            </p>
          </div>
        </section>

        <section style={{ marginTop: "5rem", marginBottom: "6rem" }}>
          <div className="container" id="#swipes">
            <h1 className="fw-bold text-center" style={{ fontSize: "32px" }}>
              High-Converting Email Swipes
            </h1>
            <p className="text-center mt-4">
              Swipes are constantly updated for highest performance.
            </p>
            <p className="text-center mt-4">
              <a onClick={handleDownload} style={{ cursor: "pointer" }}>
                Click here
              </a>{" "}
              to get immediate access to high-converting swipes to creatives.
            </p>
            <p className="text-center mt-4">
              In the emails, make sure you replace “CBID” or “xxx” with your own
              Clickbank affiliate ID or nickname.
              <br />
              And insert your own tracking reference following the “tid=”
              (optional).
            </p>
            <p className="text-center mt-4">
              <span className="h6 fw-bold">IMPORTANT:</span> We only allow you
              to promote to opted-in subscribers. No spamming is allowed. You
              will be blacklisted immediately if you send unsolicited emails.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default NeuroTest;
