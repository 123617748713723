import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/stp-logo.png";

const Navbar = ({ links }) => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-black fixed-top py-5 px-5"
      style={{ height: "70px" }}
    >
      <div className="container-fluid">
        <a className="navbar-brand text-white" href="/neurotest">
          <img src={logo} width={"50%"} style={{ minWidth: "200px" }} />
        </a>
        {/* <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon" style={{color: 'white'}}></span>
        </button> */}
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto ">
            {" "}
            {links &&
              links.map((link, index) => (
                <Link
                  className="nav-link text-white d-flex justify-content-center align-items-center fw-bold"
                  key={index}
                  to={link.to}
                >
                  <div style={{textWrap: 'nowrap', fontSize: '18px'}}>{link.text}</div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
