import React from "react";

const Footer = () => {
  return (
    <footer className="text-center text-lg-start" style={{backgroundColor: '#1a1a1a'}}>
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center d-flex justify-content-center" style={{flexWrap: 'wrap', rowGap: '1rem'}}>
              <a href="https://tryneurotest.com" className="me-4 link-secondary text-white">
                Get Started
              </a>
              <a href="https://tryneurotest.com/privacy-policy-cb.php" className="me-4 link-secondary text-white">
                Privacy
              </a>
              <a href="https://tryneurotest.com/terms-conditions-cb.php" className="me-4 link-secondary text-white">
                Terms & Conditions
              </a>
              <a href="https://tryneurotest.com/refund-policy-cb.php" className="me-4 link-secondary text-white">
                Refund Policy
              </a>
              <a href="https://tryneurotest.com/support-cb.php" className="me-4 link-secondary text-white">
                Product Support
              </a>
              <a href="https://www.clickbank.com/support/" target="_blank" className="me-4 link-secondary text-white">
                Order Support
              </a>
            </div>
            <hr />
            <div className="text-center footer-content" >
              <p className="text-white">
                Copyright &copy; {new Date().getFullYear()} Prime 2.0 Nutrition.
              </p>
              <p className="small text-white">
                Please note that the information we provide is not intended to
                replace consultation with a qualified medical professional. We
                encourage you to inform your physician of changes you make to
                your lifestyle and discuss these with him or her. For questions
                or concerns about any medical conditions you may have, please
                contact your doctor.{" "}
              </p>
              <p className="small text-white">
                Additional disclaimer and legal information...
              </p>
              <p className="small text-white">
                Statements on this website have not been evaluated by the Food
                and Drug Administration. Products are not intended to diagnose,
                treat, cure or prevent any disease. If you are pregnant,
                nursing, taking medication, or have a medical condition, consult
                your physician before using our products. The website’s content
                and the product for sale is based upon the author’s opinion and
                is provided solely on an “AS IS” and “AS AVAILABLE” basis. You
                should do your own research and confirm the information with
                other sources when searching for information regarding health
                issues and always review the information carefully with your
                professional health care provider before using any of the
                protocols presented on this website and/or the product sold
                here. ClickBank is the retailer of products on this site.
                CLICKBANK® is a registered trademark of Click Sales, Inc., a
                Delaware corporation located at 1444 South Entertainment Ave,
                Suite 410, Boise, Idaho, 83709, USA and used by permission.
                ClickBank’s role as retailer does not constitute an endorsement,
                approval or review of these products or any claim, statement or
                opinion used in promotion of these products.
              </p>
              <p className="small text-white">
                * For international shipping (outside of the United States),
                shipping fees will apply.
              </p>
            </div>
           </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
